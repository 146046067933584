import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ContactService} from "../../Shared/Services/contact.service";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import {CompanyPresenterListRenderer} from "../CellRenderers/company-presenter-list-renderer.component";
import {EventPresenterActivity} from "../../Shared/Models/event-presenter-activity";
import {AccountService} from "../../Shared/Services/account.service";

@Component({
  selector: "app-calendar-events-grid",
  template: `
    <div *ngIf="showGridWhenNoRows || (rowData && rowData.length > 0)">
      <label>Calendar Events:</label>
      <ag-grid-angular
        style="width: 100%; height: 300px"
        class="ag-theme-balham"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
    </div>
  `
})
export class CalendarEventsGridComponent extends BaseGridComponent<EventPresenterActivity> implements OnInit {

  @Input()
  accountId: number | undefined;

  @Input()
  contactId: number | undefined;

  @Input()
  showGridWhenNoRows: boolean = true;

  @Output()
  loading = new EventEmitter<boolean>();

  columnDefs: ColDef[] = [
    {
      field: 'Date',
      headerName: 'Date',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sort: 'desc'
    },
    {
      field: 'Id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 3,
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: 1,
    },
  ];

  presenterColDef: ColDef =     {
    field: 'Presenters',
    headerName: '#Pres',
    headerTooltip: '# of Presenters',
    maxWidth: 80,
    flex: 1,
    cellRenderer: CompanyPresenterListRenderer,
    cellRendererParams: (params: ICellRendererParams<EventPresenterActivity>) => {
      return {
        HeaderName: "Source Company",
        ShowCompanyName: false,
        Placement: 'left'
      }
    },
    type: 'numericColumn',
  };

  constructor(private contactService: ContactService, private accountService: AccountService) {
    super();
  }

  ngOnInit(): void {
    this.loading.emit(true)
    if (this.contactId) {
      this.contactService.getContactEventsRequests(this.contactId)
        .subscribe(data => {
          this.rowData = data;
          this.loading.emit(false);
        });
    } else if (this.accountId) {
      this.columnDefs.push(this.presenterColDef);
      this.accountService.getAccountEventsRequests(this.accountId)
        .subscribe(data => {
          this.rowData = data;
          this.loading.emit(false);
        })
    }
  }
}
