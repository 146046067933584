<div class="modal-content">
  <form [formGroup]="companyReassignForm" id="companyReassignForm">
    <div class="modal-header">
      <h3 class="modal-title">
        Reassign Company Requests and Events
      </h3>
    </div>
    <div class="modal-body">
      <div class="flex-hbox flex-gap" style="padding-bottom: 15px">
        <div class="flex">
          <label>Choose Source Account:</label>
          <app-multi-account-select formControlName="sourceCompany"
                                    [maxItems]="1"
                                    [includeAccountIdInLabel]="true">
          </app-multi-account-select>
        </div>
        <div class="flex">
          <label>Choose Target Account:</label>
          <app-multi-account-select formControlName="targetCompany"
                                    [maxItems]="1"
                                    [includeAccountIdInLabel]="true">
          </app-multi-account-select>
        </div>
      </div>
      <app-loading class="flex" *ngIf="companyReassignForm.get('sourceCompany').value[0] && showLoading"></app-loading>
      <app-calendar-events-grid *ngIf="companyReassignForm.get('sourceCompany').value[0]" [ngStyle]="{'display' : showLoading ? 'none' : ''}"
                                [accountId]="companyReassignForm.get('sourceCompany').value[0]"
                                (loading)="isPresenterGridLoading($event)">
      </app-calendar-events-grid>
      <div *ngIf="errorMessage" style="padding-top: 15px">
        <span class="text-danger" >{{errorMessage}}</span>
      </div>
    </div>
    <div class="modal-footer">
      <div class="flex-hbox">
        <button class="btn btn-sm btn-primary flex"
                (click)="reassignCompany()"
                [disabled]="!formValid() || showLoading || isSaving">
          Reassign
        </button>
        <button class="btn btn-sm btn-default flex" (click)="close()">Cancel</button>
      </div>
    </div>
  </form>
</div>
