import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchService {
    constructor(private baseHttp: BaseHttpService){}

    searchAccounts(query: string): Observable<AccountSearchResult[]> {
        return this.baseHttp.getData(`/search/account?query=${query}`);
    }

    searchContacts(query: string): Observable<any[]> {
        return this.baseHttp.getData(`/search/contact?query=${query}`);
    }

    searchTickers(query: string): Observable<any[]> {
        return this.baseHttp.getData(`/search/ticker?query=${query}`);
    }
}

export interface AccountSearchResult {
    Id: number;
    Description: string;
    Location: string;
    ComdolId: string;
    IsDeleted: boolean;
}
