<div class="page-content flex-vbox">

    <app-page-banner
        pagetitle="Corp Travel Admin Dashboard"
        faicon="fa-plane"
        faiconcolor="#800000">
    </app-page-banner>

    <div class="page-body flex" style="margin: 0 10px;">

        <div class="row" style="padding-top: 14px;">
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[hotelsProviderListPath]" [relativeTo]="null">Hotels</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[restaurantsProviderListPath]" [relativeTo]="null">Restaurants</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[transportationProviderListPath]" [relativeTo]="null">Transportation</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[airportListPath]" [relativeTo]="null">Airports</a>
            </div>
            <div class="col-sm-2" [appIfUserFeature]="corpTravelCityAdminUserFeature">
                <a class="btn btn-default btn-lg" style="display: block; min-width: 100px" [routerLink]="[travelCityListPath]" [relativeTo]="null">Cities</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block; min-width: 100px" [routerLink]="[bairdRepAnalystListPath]" [relativeTo]="null">Baird Rep & Analyst</a>
            </div>
        </div>
        <div class="row" style="padding-top: 14px;">
          <div class="col-sm-2">
            <a class="btn btn-default btn-lg" style="display: block" (click)="openCompanyReassignForm()">Reassign Company </a>
          </div>
      </div>
    </div>
</div>
