import { Injectable } from '@angular/core';
import {forkJoin, Observable, of} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import {Account} from "../../../Models/Account";
import * as moment from "moment";
import {AccountMarket} from "../../../Models/Market";
import {AccountRegion} from "../../../Models/AccountRegion";
import {AccountCommissionRank} from "../../../Components/AccountCommissionRank/AccountCommissionRank";
import {AccountMonthlyExpense, AccountMonthlyRelativePay} from "../../../Models/AccountMonthlyCommission";
import {map} from "rxjs/operators";
import {AccountBillingInfo} from "../../../Models/AccountBillingInfo";
import {EventPresenterActivity} from "../Models/event-presenter-activity";

export class AccountProfile {
    Description: string;
    InvestorTypes: string[];
    InvestorStrategy: string;
    RateCard: string;
    Notes: string;
    AumTotal: number;
    AumEquityTotal: number;
    AumDate: string | moment.Moment | Date;
    CanSeeRateCard: boolean;
    CanSeeNotes: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AccountService {

    constructor(private baseHttp: BaseHttpService) { }

    getAllAccounts(): Observable<Account[]> {
        return this.baseHttp.getData(`/account`);
    }

    getAccountSubAccounts(accountId: number): Observable<Account[]> {
        return this.baseHttp.getData(`/account/${accountId}/subaccounts`);
    }

    getAccountById(accountId: number): Observable<Account> {
        return this.baseHttp.getData(`/account/${accountId}`);
    }

    getAccountProfile(accountId: number): Observable<AccountProfile> {
        return this.baseHttp.getData(`/account/${accountId}/profile`);
    }

    GetAccountContactAvailableTeams(accountId: number): Observable<string[]> {
        return this.baseHttp.getData(`/account/${accountId}/availableteams`);
    }

    updateAccountProfile(accountId: number, profile: AccountProfile): Observable<AccountProfile> {
        return this.baseHttp.postData(`/account/${accountId}/profile`, profile);
    }

    getAccountMarkets(): Observable<AccountMarket[]> {
        return this.baseHttp.getData(`/market`);
    }

    getAccountRegions(): Observable<AccountRegion[]> {
        return this.baseHttp.getData(`/region`);
    }

    getAccountNote(accountId: number, noteType: string): Observable<string> {
        return this.baseHttp.getData(`/account/id/${accountId}/type/${noteType}/note`)
    }

    updateAccountNote(accountId: number, noteType: string, note: string): Observable<any> {
        return this.baseHttp.postData(`/account/Id/${accountId}/type/${noteType}/note`, {note: note});
    }

    getAccountRelativePay(years: number[], accountId: number, includeParent: boolean): Observable<AccountMonthlyRelativePay[]> {
        const params = {
            years: years.map(y => y.toString()),
            includeParent: includeParent.toString()
        };

        return this.baseHttp.getDataWithConfig(`/relativepay/id/${accountId}`, { params: params });
    }

    getAccountCommissionRank(accountId: number): Observable<AccountCommissionRank[]> {
        return this.baseHttp.getData(`/account/id/${accountId}/commissionrank`);
    }

    updateAccount(account: Account): Observable<Account>{
        return this.baseHttp.putData(`/account/id/${account.Id}`, account);
    }

    getAccountExpenses(years: number[], accountId: number, includeParent: boolean): Observable<AccountMonthlyExpense[]> {
        const params = {
            years: years.map(y => y.toString()),
            includeparent: includeParent.toString()
        };

        return this.baseHttp.getDataWithConfig(`/account/id/${accountId}/expenses`, {params: params});
    }

    getAccountsByIds(accountIds: number[]): Observable<Account[]> {

        if (accountIds.length === 0) return of([]);

        let accounts$ = accountIds.map(accountId => this.getAccountById(accountId));
        return forkJoin(accounts$).pipe(
            map(accounts => accounts.filter(acct => acct))
        );
    }

    setAccountBluematrixSecureLinks(accountId: number, bmSecureLinks: boolean): Observable<any> {
        return this.baseHttp.postData(`/account/${accountId}/bluematrixSecureLinks`, bmSecureLinks);
    }

    getAccountBillingInfo(accountId: number): Observable<AccountBillingInfo> {
        return this.baseHttp.getData(`/account/id/${accountId}/billinginfo`);
    }

    updateAccountBillingInfo(accountId: number, billingInfo: AccountBillingInfo) {
        return this.baseHttp.postData(`/account/id/${accountId}/billinginfo`, billingInfo);
    }

    getAccountEventsRequests(accountId: number): Observable<EventPresenterActivity[]> {
        return this.baseHttp.getData(`/account/${accountId}/presenteractivity`);
    }

    reassignAccount(fromAccountId: number, toAccountId: number): Observable<any> {
        return this.baseHttp.postData(`/account/${fromAccountId}/reassign?toAccountId=${toAccountId}`, {});
    }
}

export interface AccountComdolInfo {
    OutsourcedBrokerAccountIds: number[];
}
