import {Component, EventEmitter, OnInit} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AccountService} from "../../Shared/Services/account.service";
import {catchError, finalize} from "rxjs/operators";

@Component({
    selector: "app-company-reassign-form",
    templateUrl: "./company-reassign-form-component.html"
})
export class CompanyReassignFormComponent implements OnInit {

    showLoading: boolean = true;
    presenterGridLoading = new EventEmitter<boolean>();
    isSaving: boolean = false;
    errorMessage: string;

    companyReassignForm = this.fb.group({
        sourceCompany: this.fb.control([], [Validators.required]),
        targetCompany: this.fb.control([], [Validators.required]),
    },{});

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private accountService: AccountService,
                private toastr: ToastrService,
    ) {
    }

    ngOnInit(): void{

      this.presenterGridLoading.subscribe(loading => {
        this.showLoading = loading;
      });
    }

    reassignCompany(): void {
        if (this.formValid()) {
            this.isSaving = true;
            const sourceCompany = this.companyReassignForm.get('sourceCompany').value;
            const targetCompany = this.companyReassignForm.get('targetCompany').value;

          this.accountService.reassignAccount(sourceCompany, targetCompany)
            .pipe(
                catchError((error) => {
                    this.errorMessage = `Error reassigning company: ${error}`;
                    throw error;
                }),
                finalize(() => this.isSaving = false))
            .subscribe(() => {
                this.toastr.success('Company reassigned successfully');
                this.close();
            });
        }
    }

    close(): void {
        this.modalRef.hide()
    }

    formValid(): boolean {
        return this.companyReassignForm.valid;
    }

    isPresenterGridLoading(isLoading: boolean) {
      this.presenterGridLoading.emit(isLoading);
    }
}
