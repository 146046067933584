import {Component, OnInit} from "@angular/core";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {CorpTravelAdminRoutePaths} from "../corp-travel-admin-route-paths";
import {CompanyReassignFormComponent} from "../CompanyReassign/company-reassign-form-component";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: "app-page-dashboard",
  templateUrl: "./corptravel-admin-dashboard.component.html"
})
export class CorpTravelAdminDashboardComponent implements OnInit {

  corpTravelCityAdminUserFeature = UserFeatureName.CorpTravelCityAdmin;

  hotelsProviderListPath = CorpTravelAdminRoutePaths.HotelsProviderList;
  restaurantsProviderListPath = CorpTravelAdminRoutePaths.RestaurantsProviderList;
  transportationProviderListPath = CorpTravelAdminRoutePaths.TransportationProviderList;
  airportListPath = CorpTravelAdminRoutePaths.AirportList;
  travelCityListPath = CorpTravelAdminRoutePaths.TravelCityList;
  bairdRepAnalystListPath = CorpTravelAdminRoutePaths.BairdRepAnalystList;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit(): void {
  }

  openCompanyReassignForm(): void {
    this.modalService.show(CompanyReassignFormComponent, {animated: false, keyboard: false, backdrop: 'static', class: 'modal-lg'});
  }
}
